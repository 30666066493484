define("labs-nyc-factfinder/topics-config/acs", ["exports", "labs-nyc-factfinder/table-config/acs/demographic", "labs-nyc-factfinder/table-config/acs/economic", "labs-nyc-factfinder/table-config/acs/social", "labs-nyc-factfinder/table-config/acs/housing", "labs-nyc-factfinder/chart-config/acs/demographic", "labs-nyc-factfinder/chart-config/acs/economic", "labs-nyc-factfinder/chart-config/acs/social", "labs-nyc-factfinder/chart-config/acs/housing"], function (_exports, _demographic, _economic, _social, _housing, _demographic2, _economic2, _social2, _housing2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // ACS Profile Topics
  var _default = [{
    id: 'demographic',
    label: 'Demographic',
    selected: 'unselected',
    type: 'topic',
    children: [{
      id: 'demo-sexAndAge',
      label: 'Sex and Age',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _demographic.default.sexAndAge,
      charts: null,
      children: []
    }, {
      id: 'demo-mutuallyExclusiveRaceHispanicOrigin',
      label: 'Mutually Exclusive Race / Hispanic Origin',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _demographic.default.mutuallyExclusiveRaceHispanicOrigin,
      charts: [{
        chartConfig: _demographic2.default.raceGroup,
        chartLabel: 'Percent Distribution of Race/Hispanic Origin Groups'
      }],
      children: []
    }, {
      id: 'demo-hispanicSubgroup',
      label: 'Hispanic Subgroup',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _demographic.default.hispanicSubgroup,
      charts: [{
        chartConfig: _demographic2.default.hispanicSubgroup,
        chartLabel: 'Percent Distribution of Hispanic Subgroups'
      }],
      children: []
    }, {
      id: 'demo-asianSubgroup',
      label: 'Asian Subgroup',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _demographic.default.asianSubgroup,
      hidePreviousChart: true,
      charts: [{
        chartConfig: _demographic2.default.asianSubgroup,
        chartLabel: 'Percent Distribution of Asian Subgroups'
      }],
      children: []
    }]
  }, {
    id: 'social',
    label: 'Social',
    selected: 'unselected',
    type: 'topic',
    children: [{
      id: 'soc-householdType',
      label: 'Household Type',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _social.default.householdType,
      charts: [{
        chartConfig: _social2.default.householdType,
        chartLabel: 'Percent Distribution of Household Types'
      }],
      children: []
    }, {
      id: 'soc-relationshipToHeadOfHouseholdHouseholder',
      label: 'Relationship To Head Of Household (Householder)',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _social.default.relationshipToHeadOfHouseholdHouseholder,
      charts: null,
      children: []
    }, {
      id: 'soc-maritalStatus',
      label: 'Marital Status',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _social.default.maritalStatus,
      charts: null,
      children: []
    }, {
      id: 'soc-grandparents',
      label: 'Grandparents',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _social.default.grandparents,
      charts: null,
      children: []
    }, {
      id: 'soc-schoolEnrollment',
      label: 'School Enrollment',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _social.default.schoolEnrollment,
      charts: [{
        chartConfig: _social2.default.schoolEnrollment,
        chartLabel: 'Percent Distribution of Population 3 and Over by School Enrollment'
      }],
      children: []
    }, {
      id: 'soc-educationalAttainmentHighestGradeCompleted',
      label: 'Educational Attainment (Highest Grade Completed)',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _social.default.educationalAttainmentHighestGradeCompleted,
      charts: [{
        chartConfig: _social2.default.educationalAttainment,
        chartLabel: 'Percent Distribution of Population 25 and Over by Educational Attainment'
      }],
      children: []
    }, {
      id: 'soc-veteranStatus',
      label: 'Veteran Status',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _social.default.veteranStatus,
      charts: null,
      children: []
    }, {
      id: 'soc-disabilityStatusOfTheCivilianNoninstitutionalizedPopulation',
      label: 'Disability Status Of The Civilian Noninstitutionalized Population',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _social.default.disabilityStatusOfTheCivilianNoninstitutionalizedPopulation,
      charts: null,
      children: []
    }, {
      id: 'soc-residence1YearAgo',
      label: 'Residence 1 Year Ago',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _social.default.residence1YearAgo,
      charts: [{
        chartConfig: _social2.default.residence1YearAgo,
        chartLabel: 'Percent Distribution of Population who Lived in a Different House 1 Year Ago'
      }],
      children: []
    }, {
      id: 'soc-placeOfBirth',
      label: 'Place Of Birth',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _social.default.placeOfBirth,
      charts: [{
        chartConfig: _social2.default.placeOfBirth,
        chartLabel: 'Percent Distribution of Total Population by Place of Birth'
      }, {
        chartConfig: _social2.default.foreignBorn,
        chartLabel: 'Percent Distribution of Foreign-Born by World Region of Birth'
      }],
      children: []
    }, {
      id: 'soc-uSCitizenshipStatus',
      label: 'U.S. Citizenship Status',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _social.default.uSCitizenshipStatus,
      charts: null,
      children: []
    }, {
      id: 'soc-yearOfEntry',
      label: 'Year Of Entry',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _social.default.yearOfEntry,
      charts: null,
      children: []
    }, {
      id: 'soc-languageSpokenAtHome',
      label: 'Language Spoken At Home',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _social.default.languageSpokenAtHome,
      charts: null,
      children: []
    }, {
      id: 'soc-ancestry',
      label: 'Ancestry',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _social.default.ancestry,
      charts: null,
      children: []
    }, {
      id: 'soc-computersAndInternetUse',
      label: 'Computers and Internet Use',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _social.default.computersAndInternetUse,
      charts: null,
      children: []
    }]
  }, {
    id: 'economic',
    label: 'Economic',
    selected: 'unselected',
    type: 'topic',
    children: [{
      id: 'econ-employmentStatus',
      label: 'Employment Status',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _economic.default.employmentStatus,
      charts: null,
      children: []
    }, {
      id: 'econ-commuteToWork',
      label: 'Commute to Work',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _economic.default.commuteToWork,
      charts: [{
        chartConfig: _economic2.default.commuteToWork,
        chartLabel: 'Percent Distribution of Workers by Means of Transportation to Work'
      }],
      children: []
    }, {
      id: 'econ-occupation',
      label: 'Occupation',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _economic.default.occupation,
      charts: [{
        chartConfig: _economic2.default.occupation,
        chartLabel: 'Percent Distribution of Workers by Occupation'
      }],
      children: []
    }, {
      id: 'econ-industry',
      label: 'Industry',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _economic.default.industry,
      charts: null,
      children: []
    }, {
      id: 'econ-classOfWorker',
      label: 'Class of Worker',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _economic.default.classOfWorker,
      charts: [{
        chartConfig: _economic2.default.classOfWorker,
        chartLabel: 'Percent Distribution of Workers by Class of Worker'
      }],
      children: []
    }, {
      id: 'econ-incomeAndBenefits',
      label: 'Income and Benefits',
      tooltip: 'In 2023 inflation-adjusted dollars',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _economic.default.incomeAndBenefits,
      hidePreviousChart: true,
      charts: [{
        chartConfig: _economic2.default.incomeAndBenefits,
        chartLabel: 'Percent Distribution of Household Income'
      }],
      children: []
    }, {
      id: 'econ-earnings',
      label: 'Earnings',
      selected: 'unselected',
      type: 'subtopic',
      tooltip: 'In 2023 inflation-adjusted dollars',
      tableConfig: _economic.default.earnings,
      charts: null,
      children: []
    }, {
      id: 'econ-healthInsuranceCoverage',
      label: 'Health Insurance Coverage',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _economic.default.healthInsuranceCoverage,
      charts: null,
      children: []
    }, {
      id: 'econ-incomeInPast12MonthsIsBelowThePovertyLevel',
      label: 'Income in the Past 12 Months Below Poverty Level',
      selected: 'unselected',
      tooltip: "For alternative NYC poverty measure, please see website of the Mayor's Office for Economic Opportunity",
      type: 'subtopic',
      tableConfig: _economic.default.incomeInPast12MonthsIsBelowThePovertyLevel,
      charts: null,
      children: []
    }, {
      id: 'econ-ratioOfIncomeToPovertyLevel',
      label: 'Ratio of Income to Poverty Level',
      selected: 'unselected',
      tooltip: "Ratio of income in the past 12 months to poverty level",
      type: 'subtopic',
      tableConfig: _economic.default.ratioOfIncomeToPovertyLevel,
      charts: [{
        chartConfig: _economic2.default.ratioOfIncomeToPovertyLevel,
        chartLabel: 'Percent Distribution of Population by Ratio of Income to Poverty Level'
      }],
      children: []
    }]
  }, {
    id: 'housing',
    label: 'Housing',
    selected: 'unselected',
    type: 'topic',
    children: [{
      id: 'hou-housingOccupancy',
      label: 'Housing Occupancy',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _housing.default.housingOccupancy,
      charts: null,
      children: []
    }, {
      id: 'hou-unitsInStructure',
      label: 'Units in Structure',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _housing.default.unitsInStructure,
      charts: null,
      children: []
    }, {
      id: 'hou-yearStructureBuilt',
      label: 'Year Structure Built',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _housing.default.yearStructureBuilt,
      charts: null,
      children: []
    }, {
      id: 'hou-rooms',
      label: 'Rooms',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _housing.default.rooms,
      charts: null,
      children: []
    }, {
      id: 'hou-housingTenure',
      label: 'Housing Tenure',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _housing.default.housingTenure,
      charts: [{
        chartConfig: _housing2.default.housingTenure,
        chartLabel: 'Percent Distribution of Housing Tenure'
      }],
      children: []
    }, {
      id: 'hou-yearHouseholderMovedIntoUnit',
      label: 'Year Householder Moved Into Unit',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _housing.default.yearHouseholderMovedIntoUnit,
      charts: null,
      children: []
    }, {
      id: 'hou-vehiclesAvailable',
      label: 'Vehicles Available',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _housing.default.vehiclesAvailable,
      charts: [{
        chartConfig: _housing2.default.vehiclesAvailable,
        chartLabel: 'Percent Distribution of Households by Vehicles Available'
      }],
      children: []
    }, {
      id: 'hou-occupantsPerRoom',
      label: 'Occupants per Room',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _housing.default.occupantsPerRoom,
      charts: null,
      children: []
    }, {
      id: 'hou-value',
      label: 'Value',
      tooltip: 'In 2023 inflation-adjusted dollars',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _housing.default.value,
      hidePreviousChart: true,
      charts: [{
        chartConfig: _housing2.default.value,
        chartLabel: 'Percent Distribution of Owner-occupied Households by Housing Value'
      }],
      children: []
    }, {
      id: 'hou-mortgageStatus',
      label: 'Mortgage Status',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _housing.default.mortgageStatus,
      charts: null,
      children: []
    }, {
      id: 'hou-selectedMonthlyOwnerCostsAsAPercentageOfHouseholdIncomeSmocapi',
      label: 'Selected Monthly Owner Costs as a Percentage of Household Income',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _housing.default.selectedMonthlyOwnerCostsAsAPercentageOfHouseholdIncomeSmocapi,
      charts: null,
      children: []
    }, {
      id: 'hou-grossRent',
      label: 'Gross Rent',
      tooltip: 'In 2023 inflation-adjusted dollars',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _housing.default.grossRent,
      hidePreviousChart: true,
      charts: [{
        chartConfig: _housing2.default.grossRent,
        chartLabel: 'Percent Distribution of Renter-occupied Households by Gross Rent'
      }],
      children: []
    }, {
      id: 'hou-grossRentAsAPercentageOfHouseholdIncomeGrapi',
      label: 'Gross Rent as a Percentage of Household Income (GRAPI)',
      selected: 'unselected',
      type: 'subtopic',
      tableConfig: _housing.default.grossRentAsAPercentageOfHouseholdIncomeGrapi,
      charts: [{
        chartConfig: _housing2.default.grossRentGrapi,
        chartLabel: 'Percent Distribution of Renter-occupied Households by Gross Rent as a Percentage of Household Income'
      }],
      children: []
    }]
  }];
  _exports.default = _default;
});