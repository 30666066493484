define("labs-nyc-factfinder/templates/components/default-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qtP7VF3/",
    "block": "[[[8,[39,0],null,[[\"@open\",\"@closeModal\"],[[30,0,[\"openModal\"]],[30,0,[\"toggleModal\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"h1\"],[14,0,\"header-large\"],[12],[1,\"\\n    NYC Population FactFinder has been updated with 2019-2023 American Community Survey (ACS) data. 2018-2022 ACS data are still available in the ACS sub-section of \"],[10,3],[14,6,\"https://www.nyc.gov/site/planning/planning-level/nyc-population/american-community-survey.page\"],[14,\"target\",\"_blank\"],[12],[1,\"NYC City Planning’s Population Division\"],[13],[1,\" website.\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"h1\"],[14,0,\"header-large mobile-header\"],[12],[1,\"\\n    NYC Population FactFinder is optimized for desktop screen widths.\\n  \"],[13],[1,\"\\n\\n  \"],[10,2],[14,0,\"lead mobile-body\"],[12],[1,\"\\n    Mobile support for NYC Population FactFinder will be added in the future.\\n    For the best experience, please use a browser with a minimum width of\\n    1280px.\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"reveal-modal\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/default-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});